import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SearchParams } from 'src/app/interfaces/search.interface';

@Component({
  selector: 'app-search-practice',
  templateUrl: './search-practice.component.html',
  styleUrls: ['./search-practice.component.css']
})
export class SearchPracticeComponent implements OnInit {

  @Output() doSearch: EventEmitter<SearchParams> = new EventEmitter();

  public form: UntypedFormGroup;
  
  constructor( private fb: UntypedFormBuilder ) {}
  
  ngOnInit(): void {
    this.crearFormulario();
  }

  private crearFormulario(): void {
    this.form = this.fb.group({
      name: ['', []],
      from: ['', []],
      to:   ['', []],
    });
  }

  
  private getInputDateTime( fecha: string ): string {
    if ( fecha ) {
      return fecha.replace( ' ',  'T' );
    } else {
      return null;
    }
  }

  resetForm(): void {
    this.form.setValue({
      name: '',
      from: '',
      to: '',
    });
  }
  

  enviar(): void {

    if ( this.form.invalid ) {
      console.error('Form inválido ');
      // TOTDO:  Gestionar error con mensaje rojo en pantalla
      //Swal.fire( 'Error de validación', 'El formulario tiene errores', 'error' );
      return;
    }

    // console.log(this.form.value);
    const name   = this.form.value['name'];
    const from   = this.form.value['from'];
    const to     = this.form.value['to']
    const s:SearchParams = { 
      name,
      from: from == '' ? null : new Date( this.getInputDateTime(from) ),
      to:   to == '' ? null : new Date( this.getInputDateTime(to) ),
    }

    this.doSearch.emit(s);
    
  }

}
