<div class="container mt-2">
    <app-club *ngIf="club" [club]="club"></app-club>
    <h5 class="section mt-2" [attr.id]="'championships'">{{ 'HOME.PRACTICES' | translate }}</h5>
    <app-search-practice (doSearch)="search( $event )"/>
  
    <div *ngIf="data?.items.length === 0" class="col animated fadeIn fast">
        <div class="col">

            <div class="alert alert-info text-center" role="alert">
                <h5 class="alert-heading"></h5>
                <i class="fa fa-ban fa-2x"></i>
                <p class="mb-0">{{ 'HOME.NO_RESULTS' | translate }}</p>
            </div>
        </div>
    </div>

        <div *ngIf="data?.items.length > 0" class="col animated fadeIn fast">
        <table class="table table-striped table-sm" style="font-size: 0.8em;">
            <thead class="thead-dark">
            <tr>
                <th class="date-header">{{ 'HOME.PUBLICATION_TABLE.Date' | translate}}</th>
                <th class="name-header">{{ 'DICTIONARY.racer' | translate}}</th>
                <!-- <th class="name-header">Clasificación</th> -->
                <th class="name-header">{{ 'DICTIONARY.duration' | translate}}</th>
                <th class="name-header">{{ 'DICTIONARY.laps' | translate}}</th>
                <th class="name-header">{{ 'DICTIONARY.totalTime' | translate}}</th>
                <th class="name-header">{{ 'DICTIONARY.meanTime' | translate}}</th>
                <th class="name-header">{{ 'DICTIONARY.bestTime' | translate}}</th>
                <th class="name-header">{{ 'DICTIONARY.onLap' | translate}}</th>
            </tr>
            </thead>
            <tbody>
                <tr class="animated fadeIn fast" *ngFor="let item of data.items | paginate: { currentPage: page, itemsPerPage: itemsPerPage, totalItems: totalItems, id: id }">
                    <td><span class="date-column">{{ item.date | date: 'y-MM-dd HH:mm:ss' }}</span></td>
                    <td>{{ item.racerName }}{{ item.racerNick?' ('+item.racerNick+')':''}}</td>
                    <!-- <td class="name-header">{{ item.classificationType | classificationType | translate }}</td> -->
                    <td class="name-header">{{ item.duration }}</td>
                    <td class="name-header">{{ item.lapCount }}</td>
                    <td class="name-header">{{ item.totalTime }}</td>
                    <td class="name-header">{{ item.meanTime/1000 }}</td>
                    <td class="name-header">{{ item.bestTime/1000 }}</td>
                    <td class="name-header">{{ item.bestLap }}</td>
                </tr>
            </tbody>
        </table>
        <pagination-controls [id]="id" *ngIf="(data?.items) && (data.items.length < data.paginationInfo.totalRecords)" (pageChange)="pageChanged($event)"></pagination-controls>
    </div>
</div>
  