import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { UsuarioModel, UsuarioList } from '../model/Usuario.model';

interface LoginResponse {
  id: string;
  token: string;
  token_expiration: string;
  userName: string;
  role?: string;
  unixTokenExpiration?: number;
  features: string[];
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private AUTH_KEY_LEN = 10;
  private VERSION_SETTINGS = '2';

  private url = `${ environment.resultsUrl }/api.php`;

  public usuarioActivo: string;
  public loginInfo: LoginResponse;

  public usuarios: UsuarioModel[] = [];
  public roles: string[] = [];

  get userName(): string {
    return this.usuarioActivo;
  }

  get userToken(): string {

    if ( this.loginInfo ) {
      return this.loginInfo.token;
    }
    else {
      return '';
    }

  }

  get soySuperAdministrador(): boolean {
    return this.loginInfo?.role === "SUPER_ADMIN";
  }

  get soyAdministrador(): boolean {
    return this.loginInfo?.role === "TRACK_ADMIN";
  }

  get estaAutenticado(): boolean {

    let autenticado = false;

    if ( this.userToken && (this.userToken.length > 2) ) {

      const now = new Date();
      if ( this.loginInfo.token_expiration > now.toISOString() ) {
        autenticado = true;
      }
      else {
        this.logout();
      }

    }

    return autenticado;

  }

  get headers(): HttpHeaders {
    return new HttpHeaders()
      .set('Zround-Token', this.userToken)
      .set('Content-Type', 'application/x-www-form-urlencoded');
  }

  constructor( private http: HttpClient) {
    this.leerToken();
  }

  private setSecurityInfo( usr: string = '', token?: string ): void {
    this.usuarioActivo = usr;
    if ( token && (token.length > 0 )) {
      this.loginInfo =  JSON.parse( token );
    }
  }

  logout(): void {
    localStorage.removeItem('token');
    this.setSecurityInfo();
    this.loginInfo = null;
  }

  login( usr: string, pass: string ): Observable<LoginResponse> {

    const headers = new HttpHeaders({
      Hash: '0',
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    let params = new HttpParams();
    params = params.append('rquest','userLogon');
    params = params.append('user', usr );
    params = params.append('pwd', pass);

    const options = { headers };

    return this.http.post(this.url,
      params,
      options
    ).pipe(
      map( (resp: LoginResponse) => {
        this.usuarioActivo = usr;
        this.loginInfo = resp;
        this.loginInfo.token_expiration = this.loginInfo.token_expiration.replace(' ', 'T' );
        this.loginInfo.unixTokenExpiration = (new Date(this.loginInfo.token_expiration)).getTime();
        this.guardarToken( this.loginInfo );
        return this.loginInfo;
      })
    );

  }

  private guardarToken( loginResp: LoginResponse ): void {

    localStorage.setItem('token', JSON.stringify(loginResp) );
  }

  leerToken(): string {

    const token = localStorage.getItem('token');

    if ( token ) {
      this.setSecurityInfo( localStorage.getItem('usuario'), token );
    } else {
      this.setSecurityInfo();
    }

    return this.userToken;

  }

  getRoles() {

    // Roles are cached. If there is data it is returned with calling API
    if ( this.roles.length > 0 ) {
      return of(this.roles);
    }

    let params = new HttpParams();
    params = params.append( 'rquest', 'role' );
    params = params.append( 'username', this.usuarioActivo );

    return this.http.get( this.url, {
      headers: this.headers,
      params
    }
    ).pipe(
      map( (resp: any ) => {
        this.roles = resp.roles;
        return resp;
      })
    );

  }

  getUsuario( login: string ): Observable<UsuarioModel> {
    let params = new HttpParams();
    params = params.append('rquest','userInfo');
    params = params.append('requestUserName', login );

    return this.http.get<UsuarioModel>( this.url, { headers: this.headers, params });
  }

  getUsuarios( page: number, pageSize: number, registered: boolean, filter: string ) {

    let params = new HttpParams();
    params = params.append('rquest','userList');
    params = params.append('username', this.usuarioActivo );
    params = params.append('page', page.toString() );
    params = params.append('pageSize', pageSize.toString() );
    params = params.append('registered', registered.toString() );
    params = params.append('filter', filter );

    return this.http.get<UsuarioList>( this.url, {
      headers: this.headers,
      params
    }
    ).pipe(
      map( (resp: UsuarioList ) => {
        this.usuarios = resp.users;
        // console.log( resp );
        return resp;
      })
    );

  }

  generateRandom( length: number = this.AUTH_KEY_LEN ): string {

    let result             = '';
    const characters       = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz0123456789!@#$&/=?+-;';
    const charactersLength = characters.length;

    for ( let i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;

  }

  guardaUsuario( usuario: UsuarioModel, imagen: File ) {

    if ( !usuario.id && !this.soySuperAdministrador ) {
      return of(null);
    }

    let params = new HttpParams();
    params = params.append( 'rquest', 'userSettings');

    const formData = new FormData();
    formData.append( 'data', JSON.stringify(usuario));
    formData.append( 'logo', imagen);

    const headers = new HttpHeaders()
      .set( 'Zround-Token', this.userToken )
      .set( 'Version', this.VERSION_SETTINGS );

    return this.http.post( this.url, formData, {
      headers,
      params
    }
    );

  }



}
