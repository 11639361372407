<nav class="fixed-nav-bar navbar navbar-expand-lg navbar-dark bg-dark container-fluid animated fadeIn fast">
  <a class="navbar-brand" target="_blank" href="https://www.zround.com"></a>
  <img style="margin-top: -2px; background-color: #d0d0d0; border-radius: 10px;" [src]="logoUrl" height="50px">

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto ml-2">
      
      <li class="nav-item">
        <a class="nav-link trasparent"
           [routerLink]="'.'"
           (click)="resetTrack()">
           {{ 'HOME.HOME' | translate }}
        </a>
      </li>
      
      <li class="nav-item">
        <a class="nav-link"
           [routerLink]="'.'"
           (click)="resetTrack()"
           [fragment]="'championships'">
           {{ 'HOME.CHAMPIONSHIPS' | translate }}
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link"
           [routerLink]="'.'"
           (click)="resetTrack()"
           [fragment]="'events'">
           {{ 'HOME.EVENTS' | translate }}
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link"
           [routerLink]="'.'"
           (click)="resetTrack()"
           [fragment]="'races'">
           {{ 'HOME.RACES' | translate }}
        </a>
      </li>

      <li *ngIf="soyTrackAdmin" class="nav-item">
        <a class="nav-link"
           [routerLink]="trackUrl">
           {{ trackName }}
        </a>
      </li>

      <li *ngIf="selectedTrack" class="nav-item">
        <a class="nav-link"
           [routerLink]="practiceTrackUrl">
           {{ 'HOME.PRACTICES' | translate }}
        </a>
      </li>

      <li class="nav-item" *ngIf="soyAdmin">
        <a class="nav-link"
           [routerLink]="'/users'">
           {{ 'HOME.USERS' | translate }}
        </a>
      </li>

    </ul>

    <ul class="navbar-nav mr-3 ml-2">
      <li><button class="btn btn-outline" (click)="switchLang( 'es' )"><img src="./assets/img/es.bmp" /></button></li>
      <li><button class="btn btn-outline" (click)="switchLang( 'en' )"><img src="./assets/img/gb.bmp" /></button></li>
      <li><button class="btn btn-outline" (click)="switchLang( 'jp' )"><img src="./assets/img/jp.bmp" /></button></li>      
      <li>
        <div class="text-right d-flex justify-content-end" *ngIf="_auth.estaAutenticado">
          <a class="nav-link"
            [routerLink]="'/user/' + trackName">
            <i class="fa-regular fa-user"></i> {{ _auth.userName }}
          </a>
          <button class="btn btn-danger mr-auto" (click)="logout()">{{ 'HOME.LOGOUT' | translate }}</button>
        </div>
        <div *ngIf="!_auth.estaAutenticado">
          <button class="btn btn-secondary mr-auto" (click)="login()">{{ 'HOME.LOGIN' | translate }}</button>
        </div>
      </li>
    </ul>
  </div>
</nav>