import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'classificationType'
})
export class ClassificationTypePipe implements PipeTransform {

  transform(value: string): string {
    switch( value ) {
      case '0': return 'DICTIONARY.laps';
      case '1': return 'DICTIONARY.time';
      default:
        return '???';
    }
  }

}
