import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { environment } from 'src/environments/environment';

import { ResultsService } from '../../services/results.service';
import { SearchResponseItem } from '../../interfaces/search.interface';
import { TablePagination } from '../../interfaces/table-pagination.interface';
import { AuthService } from '../../adm/services/auth.service';
import { UserInfo } from 'src/app/interfaces/userInfo.interface';


const resultsUrl = environment.resultsUrl;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: [ './navbar.component.css',
  ]
})
export class NavbarComponent implements OnInit {

  public logoUrl = environment.logo;
  public searchResult: SearchResponseItem[] = [];
  public resultsUrl = resultsUrl;
  public terminoBusqueda = '';
  public pagination: TablePagination = {
    totalRecords: 0,
    page: 1,
    pageNext: null,
  }

  get selectedTrack(): UserInfo {
    if ( this._results.practiceEnabled ) {
      return this._results.currentTrack;
    }
    else {
      return null;
    }
  }

  get soyAdmin(): boolean {
    return this._auth.soySuperAdministrador;
  }

  get soyTrackAdmin(): boolean {
    return this._auth.soyAdministrador || this._auth.soySuperAdministrador;
  }

  get practiceTrackUrl(): string {
    return `/practices/${ this.selectedTrack.id }`;
  }

  get trackName(): string {
    return this._auth.userName;
  }

  get trackUrl(): string {
    if ( this.soyTrackAdmin ) {
      return `/track/${ this._auth.loginInfo.id }`;
    }
    else {
      return '/';
    }
  }

  constructor(  public translate: TranslateService,
                private router: Router,
                // tslint:disable-next-line: variable-name
                public _auth: AuthService,
                // tslint:disable-next-line: variable-name
                public _results: ResultsService ) { }

  ngOnInit(): void {
    const lang = localStorage.getItem('language');
    if ( lang ) {
      this.switchLang( lang );
    }
  }

  switchLang( lang: string ): void {
    this.translate.use( lang );
    localStorage.setItem('language', lang);
  }

  searchChange( termino: string ): void {
    if ( termino.length === 0 ) {
      this.searchResult = [];
    }
  }

  resetTrack(): void {
    this._results.currentTrack = null;
  }

  searchUser( page: number ): void {

    if ( this.terminoBusqueda.length === 0 ) {
      return;
    }

    this._results.searchForUsers( this.terminoBusqueda, page )
      .subscribe( ( resp: any ) => {
        this.searchResult = resp.data;
        this.pagination.totalRecords = resp.totalRecords;
        this.pagination.page = resp.page;
        this.pagination.pageNext = resp.pageNext;
      });
  }

  buscar( f: NgForm ): void {

    this.terminoBusqueda = f.value.termino.trim();
    this.searchUser( 1 );

  }

  login() {
    this.router.navigateByUrl('/login');
  }

  logout() {
    this._auth.logout();
    this.login();
  }

}
