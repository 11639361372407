import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UserInfo } from '../../interfaces/userInfo.interface';
import { ResultsService } from 'src/app/services/results.service';
import { SearchParams } from 'src/app/interfaces/search.interface';
import { PublicationTable } from 'src/app/interfaces/publication-table.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-practices',
  templateUrl: './practices.component.html',
  styleUrls: ['./practices.component.css']
})
export class PracticesComponent {

  public club: UserInfo;
  private filter: SearchParams = null;

  public data: PublicationTable;
  public loading = true;
  public itemsPerPage: number;
  
  get page(): number {
    return this.data?.paginationInfo.page;
  }

  get totalItems() : number {
    return this.data?.paginationInfo.totalRecords;
  }

  constructor( private activatedRoute: ActivatedRoute,
               private _results: ResultsService ) {
    this.itemsPerPage = environment.practiceItemsPerPage;
    this.activatedRoute.params.subscribe( params => {
      this.getUserData( params.id );
    });
  }

  getUserData( id: number ): void {
    this._results.getUserInfoById( id )
      .subscribe( u => {
        this.club = u;
        
        if ( !this._results.currentTrack ) {
          this._results.currentTrack = u;
        }

        this.load();
      });
  }

  load( page: number = 1 ): void {
    this.loading = true;
    this._results.getPractices(this.club, this.filter, page, this.itemsPerPage )
      .subscribe( (data: PublicationTable)  => {
        // console.log( 'load practices', data );
        this.loading = false;
        this.data = data;
      },
      ( error => {
        this.loading = false;
        console.error( "Error loading practice results.", error);
      }));
  }

  search( s: SearchParams): void {
    this.filter = s;
    console.log(this.filter);
    this.load();
  }

  pageChanged( page: number ): void {
    this.load( page );
  }


}
