<div class="container-fluid mt-2 mb-3">
    <div class="row justify-content-center">

        <div class="card" style="width: 30rem;">
            <div class="card-header"><strong>{{ 'FILTER.TITLE' | translate }}</strong></div>
            <div class="card-body">
                <form autocomplete="on" [formGroup]="form" (ngSubmit)="enviar()" (keydown.enter)="enviar()" class="form-horizontal p-t-20">

                    <div class="form-group row mt-2 ml-2 mr-3">
                        <label for="name" class="col-sm-3 col-form-label">{{ 'DICTIONARY.racer' | translate }}</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" formControlName="name" id="name" [placeholder]="'FILTER.hints.racer' | translate">
                        </div>
                    </div>
                    
                    <div class="form-row">
                        <div class="form-group col-sm-6 d-flex">
                            <label for="from" class="control-label ml-20 w50">{{ 'FILTER.FROM' | translate }}</label>
                            <input type="date" class="form-control w150 ml-2" formControlName="from" id="from">
                        </div>
                        <div class="form-group col-sm-6 d-flex">
                            <label for="to" class="control-label ml-20 w50">{{ 'FILTER.TO' | translate }}</label>
                            <input type="date" class="form-control w150 ml-2" formControlName="to" id="to">
                        </div>
                    </div>

                    <div class="text-right">
                        <button class="btn btn-outline-secondary btn-sm" type="button" (click)="resetForm()">{{ 'FILTER.RESET' | translate }}</button>
                        <button class="btn btn-secondary btn-sm ml-2 mr-3" type="submit">{{ 'ACTIONS.search' | translate }}</button>
                    </div>

                </form>

            </div>
        </div>
    </div>

</div>    
