import { TablePagination } from './table-pagination.interface';

export class PublicationTable {
    items: ( PublicationTableItem | PracticeTableItem )[];
    paginationInfo: TablePagination;

    constructor() {
        this.items = [];
        this.paginationInfo = {
            totalRecords: 0,
            pageNext: null,
            page: 1
        };
    }
}

export interface PublicationTableItem {
    id: string;
    date: Date;
    name: string;
    class: string;
    userDisplayName?: string;
    userId: string;
    logo?: string;
    zid?: string;
}

export interface PracticeTableItem {
    id: string;
    date: Date;
    name: string;
    class: string;
    userDisplayName?: string;
    userId: string;
    logo?: string;
    zid?: string;
}
